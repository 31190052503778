<template>
<div>
  <Row :gutter="16">
    <Col span="6">
      <Card :bordered="false" dis-hover>
        <p slot="title">
          <i class="iconfont icon-gongdan"  style="color: #17233d"></i>
          所有工单
        </p>
        <p>
          <a style="color: #17233d;font-weight: bold; font-size: 24px">{{ worderData.length }} </a>单
        </p>
      </Card>
    </Col>
    <Col span="6">
      <Card :bordered="false" dis-hover>
        <p slot="title">
          <i class="iconfont icon-daiban" style="color: #ff9900;"></i>
          将超时工单
        </p>
        <p>
          <a style="color: #ff9900;font-weight: bold; font-size: 24px">{{worderWillExpiredData.length}} </a>单
        </p>
      </Card>
    </Col>
    <Col span="6">
      <Card :bordered="false" dis-hover>
        <p slot="title">
          <i class="iconfont icon-chaoshigongdan" style="color: #ed4014"></i>
          已超时工单
        </p>
        <p>
          <a  style="color: #ed4014;font-weight: bold; font-size: 24px">{{worderExpiredData.length}} </a>单
        </p>
      </Card>
    </Col>
    <Col span="6">
      <Card :bordered="false" dis-hover>
        <p slot="title">
          <i class="iconfont icon-wanchenggongdan" style="color: #19be6b;"></i>
          已完成工单
        </p>
        <p>
          <a  style="color: #19be6b;font-weight: bold; font-size: 24px">{{worderClosedData.length}} </a>单
        </p>
      </Card>
    </Col>
  </Row>
  <Card :bordered="false" dis-hover style="margin-top: 12px">
    <worder-tab :worder-data="tabData" @forward="forward" @showDetail="showDetail" @createWorder="createWorder" @getWorders="getWorders"></worder-tab>
  </Card>

<!-- 工单详情 -->
  <worder-base-drawer :is-show="showWorderDetail" title="工单详情" :width="1200" @closeDrawer="closeDrawer">
    <Card :bordered="true" style="margin-bottom: 16px" dis-hover>
      <p slot="title">工单信息</p>
      <Row>
        <Col span="6">
          <span style="font-weight: bold">工单标题:</span>
          {{ worderDetail.title }}
        </Col>
        <Col span="6">
          <span style="font-weight: bold">工单状态:</span>
          {{ worderState(worderDetail) }}
        </Col>
        <Col span="6">
          <span style="font-weight: bold">紧急程度:</span>
          {{ worderDetail.urgent === 1 ? "一般" : worderDetail.urgent ===2 ? "紧急" : "非常紧急" }}
        </Col>
      </Row>
    </Card>
    <Card :bordered="true" style="margin-bottom: 16px" dis-hover>
      <p slot="title">工单内容</p>
      <div v-html="worderDetail.detail"></div>
    </Card>
    <Card :bordered="true" style="margin-bottom: 16px" dis-hover>
      <p slot="title">处理记录</p>
      <Table :columns="handleColums" :data="worderDetail.handles" height="200">
        <template slot-scope="{ row }" slot="handle">
          <span v-html="row.handle"></span>
        </template>
        <template slot-scope="{ row }" slot="user">
          <span>{{ row.user.username }}</span>
        </template>
      </Table>
    </Card>
    <Card :bordered="true" style="margin-bottom: 16px" dis-hover>
      <p slot="title">流转记录</p>
      <Table :columns="forwardColums" :data="forwardData" height="200">
      <template slot-scope="{ row }" slot="state">
        <span>{{worderState(row)}}</span>
      </template>
      <template slot-scope="{ row }" slot="sender">
        <span>{{ row.sender.username }}</span>
      </template>
      <template slot-scope="{ row }" slot="receiver">
        <template v-for="recvInfo in row.receivers">
          <span :key="recvInfo._index">{{recvInfo.user.username}},</span>
        </template>
      </template>
      <template slot-scope="{ row }" slot="comment">
        <span v-html="row.forward"></span>
      </template>
    </Table>
    </Card>
    <div>
      <Divider orientation="left">回复内容</Divider>
      <quill-editor @change="onEditorChange($event)"></quill-editor>
    </div>
    <Button slot="drawer-footer" @click="closeDrawer" style="margin-right: 5px">取 消</Button>
    <Button slot="drawer-footer" @click="handleWorder" type="info" style="margin-right: 5px" ghost>回 复</Button>
    <Button slot="drawer-footer" @click="closeWorder" type="primary" style="margin-right: 5px" ghost>结 单</Button>
  </worder-base-drawer>

<!-- 新增工单-->
  <worder-base-drawer :is-show="showCreateWorder" title="新建工单" :width="1200" @closeDrawer="closeDrawer">
    <Form :model="formData" label-colon :label-width="100">
      <p slot="title">公共信息</p>
      <FormItem label="工单类型" style="margin-bottom: 16px">
        <Cascader :data="cascaderData" @on-change="handleWorderType" trigger="hover" style="width: 300px"></Cascader>
      </FormItem>
      <FormItem label="工单标题" style="margin-bottom: 16px">
        <Input v-model="formData.title" placeholder="工单标题" style="width: 300px"/>
      </FormItem>
      <FormItem label="工单处理人" style="margin-bottom: 16px">
        <Select @on-change="handleReceivers" filterable multiple placeholder="请选择处理人" style="width: 300px">
          <Option v-for="item in receivers" :value="item.id" :key="item._index">{{item.username}}</Option>
        </Select>
      </FormItem>
      <FormItem label="完成时间" style="margin-bottom: 16px">
        <DatePicker v-model="formData.require_time" type="date" @on-change="dateFormat" placeholder="Select date" style="width: 300px"></DatePicker>
      </FormItem>
      <FormItem label="工单优先级" style="margin-bottom: 16px">
        <RadioGroup v-model="formData.urgent">
          <Radio :label="1" style="color: #2d8cf0; margin-right: 18px">一般</Radio>
          <Radio :label="2" style="color: #ff9900; margin-right: 18px">紧急</Radio>
          <Radio :label="3" style="color: #ed4014">非常紧急</Radio>
        </RadioGroup>
      </FormItem>
      <FormItem label="工单内容">
        <quill-editor
            @change="onEditorChange($event)"
        ></quill-editor>
      </FormItem>
    </Form>
    <Button slot="drawer-footer" style="margin-right: 8px" @click="showCreateWorder = false">取 消</Button>
    <Button slot="drawer-footer" type="primary" @click="submit">提 交</Button>
  </worder-base-drawer>
<!--  转单对话框-->
  <Modal
      title="转单信息"
      ok-text="转 单"
      @on-ok="forwardSubmit"
      v-model="showForwardModal"
      :mask-closable="false">
    <Form :model="forwardFormData" label-colon>
    <Row>
      <Col span="24">
        <FormItem label="受理人"  prop="receivers"  style="width: 100%">
          <Select v-model="forwardFormData.receivers" multiple>
            <Option v-for="item in receivers" :value="item.id" :key="item._index">{{item.username}}</Option>
          </Select>
        </FormItem>
      </Col>
    </Row>
    <Row>
      <Col span="24">
        <FormItem label="转发意见">
          <Input type="textarea" v-model="forwardFormData.forward" :rows="4" placeholder="转发意见" />
        </FormItem>
      </Col>
    </Row>
    </Form>
  </Modal>
</div>
</template>

<script>
import {get, post} from "@/api/http"
import WorderBaseDrawer from "@/View/worderSys/WorderBaseDrawer";
import Notice from "@/components/baseNotice.js";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor';
import jwt_decode from "jwt-decode";
import moment from "moment";
import WorderTab from "../worderSys/WorderTab"

export default {
  name: "Worder",
  components: {
    WorderBaseDrawer,
    quillEditor,
    WorderTab
  },
  data () {
    return {
      showForwardModal: false,
      showWorderDetail: false,
      showCreateWorder: false,
      editorHtml: '',
      receivers: [],
      worderDetail: {},
      formData: {},
      worderData: [],
      worderOwnerData: [],
      worderClosedData: [],
      worderUpcomingdData: [],
      worderExpiredData: [],
      worderWillExpiredData: [],
      worderApproveData:[],
      content:'',
      handleData: {},
      handleColums:[
        {
          title: '处理人',
          width: 80,
          slot: 'user'
        },
        {
          title: '回复内容',
          slot: 'handle'
        },
        {
          title: '时间',
          width: 200,
          key: 'created_at'
        },
      ],
      forwardColums: [
        {
          title: '状 态',
          width: 80,
          slot: 'state'
        },
        {
          title: '转发人',
          width: 120,
          slot: 'sender'
        },
        {
          title: '受理人',
          slot: 'receiver'
        },
        {
          title: '备 注',
          slot: 'comment'
        },
        {
          title: '时 间',
          width: 200,
          key: 'created_at'
        }
      ],
      forwardData: [],
      forwardFormData: {
        worder_id: null,
        state: null,
        forward: "",
        receivers: []
      },
      cascaderData: [
        {
          value: '业务工单',
          label: '业务工单',
          children: [
            {
              value: '业务切换',
              label: '业务切换'
            },
            {
              value: '业务下线',
              label: '业务下线'
            },
            {
              value: '其他',
              label: '其他'
            }
          ]
        },
        {
          value: '跑量优化',
          label: '跑量优化',
          children: [
            {
              value: '单机跑量优化',
              label: '单机跑量优化'
            },
            {
              value: '下行拉量',
              label: '下行拉量'
            },
            {
              value: '其他',
              label: '其他'
            }
          ]
        },
        {
          value: '故障工单',
          label: '故障工单',
          children: [
            {
              value: '网络',
              label: '网络',
              children: [
                {
                  value: '拨号异常',
                  label: '拨号异常',
                },
                {
                  value: '丢包延时',
                  label: '丢包延时',
                },
                {
                  value: '带宽受限',
                  label: '带宽受限',
                },
                {
                  value: '异常分析',
                  label: '异常分析',
                },
                {
                  value: '其他',
                  label: '其他',
                }
              ]
            },
            {
              value: '硬件',
              label: '硬件',
              children: [
                {
                  value: '硬盘故障',
                  label: '硬盘故障',
                },
                {
                  value: '内存故障',
                  label: '内存故障',
                },
                {
                  value: '其他',
                  label: '其他',
                }
              ]
            }
          ],
        }
      ]
    }
  },
  methods: {
    dateFormat(date){
      return moment(date).utcOffset(8).format()
    },
    submit() {
      let data = this.$_.cloneDeep(this.formData)
      data.detail = this.editorHtml;
      post("/api/v1/worder", data)
      .then((resp)=>{
        if (resp.code === 0) {
          Notice.success("工单操作", "提交成功")
        } else {
          Notice.error("工单操作", resp.msg)
        }
        this.showCreateWorder = false;
        this.getWorders();
      })
      .catch((e)=>{
        return e
      })
    },
    handleReceivers(value){
      let receivers = value.map((item)=>{
        return {user_id: item}
      })
      this.formData.forwards = [{
        sender_id: this.getUserId(),
        state: this.formData.state,
        forward: "新建工单",
        receivers: receivers
      }]
    },
    handleWorderType(value) {
      this.formData.worder_type = {
        t1: value[0],
        t2: value[1],
        t3: value.length>2 ? value[2] : "",
        t4: value.length>3 ? value[3] : ""
      }
    },
    createWorder(){
      this.reset();
      this.showCreateWorder = true;
    },
    onEditorChange(editor) {
      this.editorHtml = editor.html;
    },
    handleWorder() {
      this.handleData.worder_id = this.worderDetail.id;
      this.handleData.state = 3;
      this.handleData.user_id = this.getUserId();
      this.handleData.handle = this.editorHtml;

      post("/api/v1/handle", this.handleData)
      .then((resp)=>{
        if (resp.code === 0) {
          Notice.success("工单操作", "回复成功")
        } else {
          Notice.error("工单操作", resp.msg)
        }
        this.getWorders();
        this.closeDrawer();
      })
      .catch((e)=>{
        return e
      })
    },
    closeWorder() {
      this.forwardFormData.worder_id = this.worderDetail.id;
      this.forwardFormData.state = 4;
      this.forwardFormData.forward = this.editorHtml;
      this.forwardFormData.sender_id = this.getUserId();
      this.forwardFormData.receivers = [{user_id: this.getUserId()}];
      post("/api/v1/forward", this.forwardFormData)
          .then((resp)=>{
            if (resp.code === 0) {
              Notice.success("工单操作", "结单成功")
            } else {
              Notice.error("工单操作", resp.msg)
            }
            this.getWorders();
            this.closeDrawer();
          })
          .catch((e)=>{
            return e
          })
    },
    worderState({state}) {
      switch (state) {
        case 1:
          return "新建"
        case 2:
          return "审核中"
        case 3:
          return "处理中"
        case 4:
          return "已结单"
      }
    },
    forward({id}) {
      this.forwardFormData.worder_id = id;
      this.forwardFormData.state = 3;
      this.showForwardModal = true;
    },
    forwardSubmit(){
      let data = this.$_.cloneDeep(this.forwardFormData);
      data.receivers = data.receivers.map((item)=>{
        return {user_id: item}
      })
      post("/api/v1/forward", data)
          .then((resp)=>{
            if (resp.code === 0) {
              Notice.success("工单操作", "转单成功")
            } else {
              Notice.error("工单操作", resp.msg)
            }
            this.getWorders();
            this.forwardFormData = {};
            this.showForwardModal = false;
          })
          .catch((e)=>{
            return e
          })
    },
    getWorders() {
      get("/api/v1/worder")
        .then((resp)=>{
          const user_id = this.getUserId();

          //所有工单
          this.worderData = resp.data;

          //待办工单
          this.worderUpcomingdData = resp.data.filter( item => {
            if(item.forwards.length === 0) {return false}

            let receivers =[...item.forwards].pop().receivers;
            for (let r of receivers) {
              if (r.user_id === user_id && item.state !== 4) {
                return true
              }
            }
          })

          //将超时工单
          this.worderWillExpiredData = resp.data.filter( item => {
            if(item.forwards.length === 0) {return false}
            let now = moment().format('x')
            let requireTime =  moment(item.require_time).format('x')
            let isWillExpired = requireTime - now > 0 && requireTime - now < 86400000    //一天后超时

            let receivers =[...item.forwards].pop().receivers;
            for (let r of receivers) {
              if (r.user_id === user_id && item.state !== 4 && isWillExpired) {
                return true
              }
            }
          })

          //超时工单
          this.worderExpiredData = resp.data.filter( item => {
            if(item.forwards.length === 0) {return false}
            let now = moment().format('x')
            let requireTime =  moment(item.require_time).format('x')
            let isExpired = requireTime - now < 0    //已超时

            let receivers =[...item.forwards].pop().receivers;
            for (let r of receivers) {
              if (r.user_id === user_id && item.state !== 4 && isExpired) {
                return true
              }
            }
          })

          //已完成工单
          this.worderClosedData = resp.data.filter(item => {
            if(item.forwards.length === 0) {return false}

            let receivers =[...item.forwards].pop().receivers;
            for (let r of receivers) {
              if (r.user_id === user_id && item.state === 4) {
                return true
              }
            }
          })

        })
        .catch((e)=>{
          console.log(e)
        })

      get("/api/v1/worder?user_id="+this.getUserId())
        .then((resp)=>{
          this.worderOwnerData = resp.data;
        })
        .catch((e)=>{
          return e
        })
    },
    showDetail(row) {
      this.worderDetail = row
      this.forwardData = row.forwards;
      this.showWorderDetail = true;
    },
    closeDrawer() {
      this.showWorderDetail = false;
    },
    getUserId() {
      let userInfo = jwt_decode(localStorage.getItem("token"))
      return userInfo.id;
    },
    getReceivers(){
      get("/api/v1/user", "").then((resp)=>{
        this.receivers = resp.data;
      }).catch(e => {
        return e
      })
    },
    reset() {
      this.formData = {
        user_id: this.getUserId(),
        title: '',
        detail: '',
        state: 1,
        forwards: [],
        handles: [],
        worder_type: {t1: "", t2: "", t3: "", t4: ""},
        urgent: 1,
        require_time: ''
      };
    }
  },
  computed: {
    tabData(){
      return [
        {label: "所有工单", name: "所有工单", data: this.worderData},
        {label: "我创建的",name: "我创建的", data: this.worderOwnerData},
        {label:  (h) => {
            return h('div', [
              h('Badge', {props: {count: this.worderUpcomingdData.length,overflowCount: "11", offset:[3,-11]}},[
                h('span', '待办工单'),
              ])
            ])
          }, name: "待办工单", data: this.worderUpcomingdData},
        {label: (h) => {
            return h('div', [
              h('Badge', {props: {count: this.worderApproveData.length,overflowCount: "11", offset:[3,-11]}},[
                h('span', '审批工单'),
              ])
            ])
          }, name: "审批工单", data: this.worderApproveData},
        {label: (h) => {
            return h('div', [
              h('Badge', {props: {count: this.worderWillExpiredData.length,overflowCount: "11", offset:[3,-11]}},[
                h('span', '将超时工单'),
              ])
            ])
          }, name: "将超时工单", data: this.worderWillExpiredData},
        {label: (h) => {
            return h('div', [
              h('Badge', {props: {count: this.worderExpiredData.length,overflowCount: "11", offset:[3,-11]}},[
                h('span', '已超时工单'),
              ])
            ])
          }, name: "已超时工单", data: this.worderExpiredData},
        {label: "已完成工单", name: "已完成工单", data: this.worderClosedData}
      ]
    }
  },
  created() {
    this.getWorders();
    this.getReceivers();
  }
}
</script>

<style scoped>
.ql-toolbar.ql-snow{
  text-align: left;
}
.quill-editor{
  height: 390px;
}
.ivu-form-item {
  margin-bottom: 0;
}

/deep/ .ivu-table-expanded-cell {
  padding: 0;
}

/deep/ .ivu-tabs-bar {
  margin-bottom: 0;
}

/deep/ .ivu-table th {
  background-color: #dcdee200;
}
</style>