var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Tabs',{attrs:{"value":"待办工单","animated":false}},[_vm._l((_vm.worderData),function(item){return _c('TabPane',{key:item._index,attrs:{"label":item.label,"name":item.name}},[_c('Table',{attrs:{"columns":_vm.colums,"data":item.data},scopedSlots:_vm._u([{key:"worderId",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}},{key:"worderType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.worderTypeFormat(row))+" ")]}},{key:"worderTitle",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.title)+" ")]}},{key:"worderUrgent",fn:function(ref){
var row = ref.row;
return [_c('span',{style:(_vm.urgentStyle(row))},[_vm._v(_vm._s(row.urgent === 1 ? "一般" : row.urgent ===2 ? "紧急" : "非常紧急"))])]}},{key:"worderCreator",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.user.username))])]}},{key:"worderReceivers",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.worderReceivers(row)))])]}},{key:"worderState",fn:function(ref){
var row = ref.row;
return [_c('Tag',{attrs:{"color":row.state === 1? 'primary': row.state===2? 'orange': row.state===3? 'warning' : 'success'}},[_vm._v(_vm._s(_vm.worderState(row)))])]}},{key:"action",fn:function(ref){
var row = ref.row;
return [_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small","ghost":""},on:{"click":function($event){return _vm.showDetail(row)}}},[_vm._v("处 理")]),_c('Tooltip',{attrs:{"content":"转单","placement":"top-end","transfer":""}},[_c('Button',{staticStyle:{"margin-right":"5px"},attrs:{"type":"primary","size":"small","icon":"ios-redo-outline","ghost":""},on:{"click":function($event){return _vm.forward(row)}}})],1),_c('Tooltip',{attrs:{"content":"催单","placement":"top-end","transfer":""}},[_c('Button',{attrs:{"type":"warning","size":"small","icon":"ios-megaphone-outline","ghost":""}})],1)]}}],null,true)})],1)}),_c('Button',{staticStyle:{"margin-right":"8px"},attrs:{"slot":"extra","type":"primary","size":"small","icon":"ios-add","ghost":""},on:{"click":_vm.createWorder},slot:"extra"},[_vm._v("新建工单")]),_c('a',{attrs:{"slot":"extra"},on:{"click":_vm.getWorders},slot:"extra"},[_c('Icon',{attrs:{"type":"ios-refresh"}}),_vm._v(" 刷新")],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }