<template>
    <Tabs value="待办工单" :animated="false">
      <TabPane  v-for="item in worderData"  :label="item.label" :name="item.name" :key="item._index">
        <Table :columns="colums" :data="item.data">
          <template slot-scope="{ row }" slot="worderId">
            {{ row.id }}
          </template>
          <template slot-scope="{ row }" slot="worderType">
            {{ worderTypeFormat(row) }}
          </template>
          <template slot-scope="{ row }" slot="worderTitle">
            {{ row.title }}
          </template>
          <template slot-scope="{ row }" slot="worderUrgent">
            <span :style="urgentStyle(row)">{{ row.urgent === 1 ? "一般" : row.urgent ===2 ? "紧急" : "非常紧急" }}</span>
          </template>
          <template slot-scope="{ row }" slot="worderCreator">
            <span>{{ row.user.username }}</span>
          </template>
          <template slot-scope="{ row }" slot="worderReceivers">
            <span>{{ worderReceivers(row) }}</span>
          </template>
          <template slot-scope="{ row }" slot="worderState">
            <Tag :color="row.state === 1? 'primary': row.state===2? 'orange': row.state===3? 'warning' : 'success'">{{ worderState(row) }}</Tag>
          </template>
          <template slot-scope="{ row }" slot="action">
            <Button @click="showDetail(row)" type="primary" size="small" style="margin-right: 5px" ghost>处 理</Button>
            <Tooltip content="转单" placement="top-end" transfer>
              <Button @click="forward(row)" type="primary" size="small" icon="ios-redo-outline" style="margin-right: 5px" ghost></Button>
            </Tooltip>
            <Tooltip content="催单" placement="top-end" transfer>
              <Button type="warning" size="small" icon="ios-megaphone-outline" ghost></Button>
            </Tooltip>
          </template>
        </Table>
      </TabPane>
      <Button
          type="primary"
          size="small"
          icon="ios-add"
          slot="extra" ghost
          style="margin-right: 8px"
          @click="createWorder"
      >新建工单</Button>
      <a slot="extra" @click="getWorders"><Icon type="ios-refresh" /> 刷新</a>
    </Tabs>
</template>

<script>
export default {
  name: "WorderTab",
  props: {
    worderData: Array,
    default: []
  },
  data(){
    return {
      colums: [
        {
          title: 'ID',
          width: 60,
          slot: 'worderId'
        },
        {
          title: '工单类型',
          width: 195,
          slot: 'worderType'
        },
        {
          title: '工单标题',
          width: 190,
          slot: 'worderTitle'
        },
        {
          title: '优先级',
          width: 100,
          slot: 'worderUrgent',
          filterMultiple: false,
          filters: [
            {
              label: '一般',
              value: 1
            },
            {
              label: '紧急',
              value: 2
            },
            {
              label: '非常紧急',
              value: 3
            }
          ],
          filterMethod (value, row) {
            return value === row.urgent
          }
        },
        {
          title: '创建人',
          width: 100,
          slot: 'worderCreator'
        },
        {
          title: '当前受理人',
          width: 220,
          slot: 'worderReceivers'
        },
        {
          title: '当前状态',
          width: 110,
          slot: 'worderState',
          filterMultiple: false,
          filters: [
            {
              label: '新建',
              value: 1
            },
            {
              label: '审批',
              value: 2
            },
            {
              label: '处理',
              value: 3
            },
            {
              label: '结单',
              value: 4
            }
          ],
          filterMethod (value, row) {
            return value === row.state
          }
        },
        {
          title: '创建时间',
          width: 170,
          key: 'created_at',
          sortable: true,
        },
        {
          title: '期望完成时间',
          width: 170,
          key: 'require_time'
        },
        {
          title: '最近更新时间',
          width: 170,
          key: 'updated_at'
        },
        {
          title: '操 作',
          align: 'left',
          width: 145,
          fixed: 'right',
          slot: 'action',
        }
      ],
    }
  },
  methods: {
    worderTypeFormat(row){
      let t = []
      const wt = row.worder_type;
      for(let key in wt) {
        if(wt[key] !== "" && key !== 'worder_id') {
          t.push(wt[key])
        }
      }
      return t.join("-")
    },
    createWorder() {
      this.$emit('createWorder')
    },
    getWorders() {
      this.$emit('getWorders')
    },
    urgentStyle({urgent}) {
      return {
        color: urgent === 1 ? "#2b85e4": urgent === 2 ? "#ff9900" : "#ed4014"
      }
    },
    worderReceivers({forwards}){
      if (forwards.length === 0) {
        return ""
      }
      let r = [...forwards].pop()
      let recvStr = []
      for(let receiver of r.receivers) {
        recvStr.push(receiver.user.username)
      }
      return recvStr.join(",")
    },
    worderState({state}) {
      switch (state) {
        case 1:
          return "新建"
        case 2:
          return "审核中"
        case 3:
          return "处理中"
        case 4:
          return "已结单"
      }
    },
    showDetail(row) {
        this.$emit("showDetail", row)
    },
    forward(row){
      this.$emit('forward', row)
    }
  }
}
</script>

<style scoped>

</style>